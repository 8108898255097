.card {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: white;
    background: rgba(255, 255, 255, .5);
    border: thin solid rgba(0, 0, 0, .01);
    border-radius: 15px;
    box-shadow:  1px 1px 1px rgba(0, 0, 0, .1);
    margin: 1rem 0;
    padding: 1rem;
}

.card-label {
    color: white;
    font-size: 1rem;
}
