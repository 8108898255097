#background {
  position: fixed;
  z-index: -1;
  height: 100vh;
  width: 100vw;
  top: 0;
  background: rgb(47, 18, 82);
  background: linear-gradient(39deg, rgb(84, 103, 197) 0%, rgb(28, 43, 124) 50%, rgb(4, 13, 51) 100%);
}

html {
  background: black;
}

body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  min-height: 100vh;
  box-sizing: border-box;
  /*background: rgb(47, 18, 82);*/
  /*background: linear-gradient(39deg, rgb(114, 87, 36) 0%, rgb(47, 18, 82) 100%);*/
}

main {
  padding: .3rem 1rem;
}
