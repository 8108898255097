@import './layout/Card/index.css';
@import './layout/Card/index.css';
@import './layout/helper.css';
@import './layout/Temp/index.css';
@import './layout/Rating/index.css';
@import './accuweather/Summary/index.css';

/* specific */

.hamburger {
    position: fixed;
    top: 20px;
    left: 0;
    background: white;
    width: 40px;
    height: 30px;
    color: black;
    border: thin solid rgba(0, 0, 0, .01);
    border-radius: 0 5px 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidenav {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: white;
    top: 0;
    left: calc(100vw);
    line-height: 30px;
    padding: 20px;
}

.sidenav li {
    border-bottom: thin solid black;
    padding: 5px;
    width: 80%;
}

.sidenav-shown {
    left: 0;
}

/* very specific */
.search-container {
    width: 100%;
    text-align: center;
    margin-top: 200px;
}

.search-container label {
    color: white;
}

.search-container input {
    color: black;
    font-size: 1.6rem;
    margin-top: 20px;
}

.search-results {
    color: white;
    width: 300px;
    margin: 30px;
    line-height: 30px;
}

.loading {
    color: white;
    width: 100%;
    text-align: center;
    padding-top: 200px;
}

.date, .city {
    width: 100%;
    text-align: center;
    margin: 4rem 0 0;
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
}

.city {
    font-size: 1rem;
    margin: 1rem 0 3rem;
}
