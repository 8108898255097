.headline {
    width: 100%;
    text-align: left;
    margin: 1.5rem 0;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5rem;
    color: white;
}

.summary {
    margin: 1.5rem 1rem;
}
