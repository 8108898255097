/* generic */

.center {
    text-align: center;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.quarter {
    width: 25%;
}

.third {
    width: 33%;
}

.half {
    width: 50%;
}
