.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    height: 100vh;
    width: 100vw;
    background: lightgrey;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
